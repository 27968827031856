import { graphql,Link } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

export const pageQuery = graphql`
  query {
    allStrapiPost(filter:{category: {eq : "BLOG"}}, sort: {fields: published_at, order: DESC}) {
      edges {
        node {
          id
          title
          body
          published_at(formatString: "MMMM DD, YYYY")
          author1
          author2
          image {
            publicURL
            childImageSharp{
              fluid {
                 base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  }
`;

const BlogPage = ( { data } ) => (
  <Layout>
    <SEO
      title="Blogs"
      description="Life Insurance News, Home Insurance News, home insurance guide, Insurance Articles, Insurance Information, Tips"
      keywords="Life Insurance News, Home Insurance News, home insurance guide, Insurance Articles, Insurance Information, Tips"
    />

    <meta httpEquiv="refresh" content="0;url=https://getmybubble.com/blog"/>

    {/* <div className="company-info-container container-fluid">
      <div className="row">
        <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div>
            <h1 className="carrier-plan-heading about-heading " style={{ lineHeight: '1.55', textAlign: 'center' }}>
              Blog
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div className="wrapper blog-outerwrap-box">
      <div className="container common-container-space">
        <div className="row">
          <div className="col-12">
            <a href="https://www.getmybubble.com/blog">All blogs</a>
          </div>
        </div>

        <div className="row">
          {data.allStrapiPost.edges.map( ( d, i ) => {
            return ( <div className="col-12" key={i}>
              <Link to={d.node.id} className="anchor-box">
                <div className="row" style={{ border: '1px solid hsl(0deg 0% 93%)', marginBottom: '30px' }}>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12 blog-padding">
                    <img src={ d.node.image && d.node.image.publicURL} alt={d.node.title} style={{ width: '100%', margin: '0' }} />
                  </div>

                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="blog-descp-box">
                      <p>{d.node.author1}{d.node.author2 ? ` and ${d.node.author2}` : ''}</p>

                      <p className="date-publish">{d.node.published_at}</p>

                      <p className="blog-main-descp blog-violet">{d.node.title}</p>

                      <h2 className="blog-violet" dangerouslySetInnerHTML={{ __html: d.node.body }}></h2>
                    </div>
                  </div>
                </div>
              </Link>
            </div> );
          } )}
        </div>

        <div className="row">
          <div className="col-12">
            <Link to="/">Go back to the homepage</Link>
          </div>
        </div>
      </div>
    </div> */}
  </Layout>
);

export default BlogPage;
